'use strict';

const $ = require('jquery');

/**
 * ナビゲーションメニューの開閉処理
 *
 * @see jquery
 */
export default function () {
    $('button[data-toggle="collapse"]').on('click', function () {
        $(this).toggleClass('in');
    });
}
