'use strict';

const $ = require('jquery');

/**
 * submitボタンの二重クリック禁止
 *
 * @see jquery
 */
export default function () {
    $('form').submit(function (event) {
        let $form = $(this);
        if ($form.data('disable')) {
            event.preventDefault();
        }
        $form.data('disable', true);
    });
}
